;(function () {
	'use strict';

	// lazy load videos
	ready(function() {

        lazyloadElements(
            document.querySelectorAll('.js-load-video'),
            function(video) {
                var hidden = video.querySelector('[hidden]');
                var content = hidden.innerHTML;
                // get content from within html comment
                content = content.replace(/<!--([\S\s]+?)-->/g, '$1');
                // replace content
                video.innerHTML = content;
                video.classList.remove('js-load-video');
                video.setAttribute('data-video-loaded', 'true');
            }
        );

	});

}());
