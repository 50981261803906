;(function () {
    'use strict';

    // init main navigation
    ready(function () {
        var buttons = document.querySelectorAll('.js-megamenu-button');
        if (buttons.length > 0) {
            Array.prototype.forEach.call(buttons, function (button) {
                var menu = new MegaMenu(button, 1120);
            });
        }
    });

    // Constructor
    function MegaMenu(button, desktopNavMinWidth)
    {

        var self = this;

        this.button = button;

        // get menu id
        if (this.button.hasAttribute('data-menu')) {
            this.menuID = this.button.getAttribute('data-menu');
        }
        if (typeof(this.menuID) == 'undefined' || this.menuID == null) {
            console.error('MegaMenu button has no data-menu attribute.');
            return;
        }

        // get menu
        this.menu = document.getElementById(this.menuID);
        if (typeof(this.menu) == 'undefined' || this.menu == null) {
            console.error('MegaMenu `' + this.menuID + '` does not exist.');
            return;
        }

        // get menu breakpoint width
        if (typeof(desktopNavMinWidth) === 'undefined' || desktopNavMinWidth == null) {
            this.desktopNavMinWidth = 1120;
        } else {
            this.desktopNavMinWidth = desktopNavMinWidth;
        }
        this.isMobileView = true;

        // update view type on resize
        window.addEventListener('resize', throttle(function () {
            var width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
            if (width >= self.desktopNavMinWidth) {
                // desktop view
                self.isMobileView = false;
            } else {
                // mobile view
                self.isMobileView = true;
            }
        }, 200));
        // trigger resize event
        if (typeof(Event) === 'function') {
            // modern browsers
            window.dispatchEvent(new Event('resize'));
        } else {
            // for IE and other old browsers
            // causes deprecation warning on modern browsers
            var evt = window.document.createEvent('UIEvents');
            evt.initUIEvent('resize', true, false, window, 0);
            window.dispatchEvent(evt);
        }

        // Add (initial) button semantics
        this.button.setAttribute('aria-haspopup', true);
        this.button.setAttribute('aria-expanded', false);
        this.button.setAttribute('role', 'button');
        this.button.setAttribute('aria-controls', this.menuID);

        // Handle button click
        this.button.addEventListener('click', function (e) {
            if (!this.isMobileView) { // desktop view
                console.log('click');
                e.preventDefault();
                this.toggle();
            }
        }.bind(this))

        // Also toggle on key interactions
        this.button.addEventListener('keydown', function (e) {
            if (!this.isMobileView) { // desktop view
                if (e.keyCode === 13 || e.keyCode === 32) {
                    // enter or space
                    console.log('enter or space');
                    e.preventDefault();
                    this.toggle();
                } else if (e.keyCode === 40) {
                    // down arrow
                    console.log('arrow down');
                    if (this.button.getAttribute('aria-expanded') !== 'true') {
                        e.preventDefault();
                        this.open();
                    } else {
                        e.preventDefault();
                        focusNext(e.currentTarget, true);
                        //this.menuItems[0].focus();
                    }
                } else if ((e.shiftKey && e.keyCode === 9)) {
                    // shift+tab
                    console.log('shift+tab');
                    this.close(false);
                } else if (e.keyCode === 9) {
                    // or tab
                    console.log('tab');
                    if (this.button.getAttribute('aria-expanded') === 'true') {
                        e.preventDefault();
                        focusNext(e.currentTarget, true);
                    }
                } else if (e.keyCode === 38) {
                    // up arrow
                    console.log('arrow up');
                    e.preventDefault();
                    this.close();
                } else if (e.keyCode === 27) {
                    // escape
                    console.log('escape');
                    e.preventDefault();
                    this.close();
                }
            }
        }.bind(this))

        // Get the all focusable elements within the menu
        this.menuItems = this.menu.querySelectorAll(
            'a[href], ' +
            'button:not([disabled]), ' +
            'input:not([disabled]), ' +
            'textarea:not([disabled]), ' +
            'select:not([disabled]), ' +
            'details, ' +
            '[tabindex]:not([tabindex="-1"])'
        );

        // Handle key presses for drawer items
        Array.prototype.forEach.call(this.menuItems, function (item) {
            // add keyboard event
            item.addEventListener('keydown', function (e) {
                if (!this.isMobileView) { // desktop view
                    if (e.shiftKey && e.keyCode === 9) {
                        // shift+tab
                        e.preventDefault();
                        focusNext(e.currentTarget, false);
                    } else if (e.keyCode === 38) {
                        // up arrow
                        e.preventDefault();
                        focusNext(e.currentTarget, false);
                    } else if (e.keyCode === 40 || e.keyCode === 9) {
                        // down arrow or tab
                        e.preventDefault();
                        focusNext(e.currentTarget, true);
                    } else if (e.keyCode === 27) {
                        // escape
                        e.preventDefault();
                        this.close();
                    }
                }
            }.bind(this))
        }.bind(this))

        var focusNext = function (item, down) {

            var next = getNext(item, down);

            console.log('next href: ' + next.getAttribute('href'));

            // make move
            if (next !== item) {
                console.log('make move');
                next.focus();
            }

        }.bind(this)

        var getNext = function (item, down) {

            var currentItem = item;
            var goingDown = down;
            var currentIndex = 0;
            var nextItem = null;

            console.log('mega menu elements: ' + this.menuItems.length);

            if (this.menuItems.length === 0) {
                nextItem = currentItem;
            } else {
                // determine current index
                Array.prototype.forEach.call(this.menuItems, function (tmp, index) {
                    if (tmp === currentItem) {
                        currentIndex = index;
                    }
                });

                console.log('current mega menu index: ' + currentIndex);

                // determine next item
                if (goingDown) {
                    console.log('mega menu going down');
                    if (currentItem === this.button && typeof this.menuItems[currentIndex] !== 'undefined') {
                        console.log('first');
                        nextItem = this.menuItems[currentIndex];
                    } else if (typeof this.menuItems[currentIndex + 1] !== 'undefined') {
                        console.log('next');
                        nextItem = this.menuItems[currentIndex + 1];
                    } else {
                        console.log('stay');
                        nextItem = currentItem;
                    }
                } else {
                    console.log('mega menu going up');

                    if (currentIndex === 0) {
                        nextItem = this.button;
                    } else {
                        nextItem = this.menuItems[currentIndex - 1];
                    }
                }
            }

            function shouldElementBeSkipped(element)
            {
                // check if element is hidden
                var style = window.getComputedStyle(element);
                return (style.display === 'none')
            }

            // check if element should be skipped
            if (shouldElementBeSkipped(nextItem)) {
                console.log('skip');
                nextItem = getNext(nextItem, down);
            }

            console.log('next href: ' + nextItem.getAttribute('href'));

            return nextItem;

        }.bind(this)

        // register outside click
        this.outsideClick = function (e) {
            if (!this.isMobileView) { // desktop view
                if (this.button.getAttribute('aria-expanded') === 'true' && !this.button.parentNode.contains(e.target)) {
                    console.log('outside click')
                    this.close(false);
                }
            }
        }
        document.addEventListener('click', this.outsideClick.bind(this));

        // initiate listeners object for public events
        this._listeners = {}
    }

    // Open methods
    MegaMenu.prototype.open = function () {
        // open nav
        this.button.setAttribute('aria-expanded', true);
        // fire open event
        this._fire('open');
        return this;
    }

    // Close methods
    MegaMenu.prototype.close = function (setFocus) {
        setFocus = typeof setFocus === "undefined" ? true : setFocus;
        // close nav
        this.button.setAttribute('aria-expanded', false);
        // set focus
        if (setFocus) {
            this.button.focus();
        }
        // fire close event
        this._fire('close');
        return this;
    }

    // Toggle methods
    MegaMenu.prototype.toggle = function () {
        var expanded = this.button.getAttribute('aria-expanded') === 'true';
        return expanded ? this.close() : this.open();
    }

    MegaMenu.prototype._fire = function (type, data) {
        if (typeof this._listeners === 'undefined') {
            this._listeners = [];
        }
        var listeners = this._listeners[type] || [];
        listeners.forEach(function (listener) {
            listener(data);
        })
    }

    MegaMenu.prototype.on = function (type, handler) {
        if (typeof this._listeners[type] === 'undefined') {
            this._listeners[type] = [];
        }
        this._listeners[type].push(handler);
        return this;
    }

    MegaMenu.prototype.off = function (type, handler) {
        var index = this._listeners[type].indexOf(handler);
        if (index > -1) {
            this._listeners[type].splice(index, 1);
        }
        return this;
    }

    // Export MegaMenu
    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = MegaMenu;
    } else if (typeof define === 'function' && define.amd) {
        define('MegaMenu', [], function () {
            return MegaMenu;
        })
    } else {
        // attach to window
        window.MegaMenu = MegaMenu;
    }
}());
