;(function () {
	'use strict';

	// init main navigation
	ready(function() {
		var buttons = document.querySelectorAll('.js-offsitedrawer-toggle');
		if (buttons.length > 0) {
			Array.prototype.forEach.call(buttons, function (button, index) {
				var nav = new OffsiteDrawer(button, 1120);
			});
		}
	});

	// Constructor
	function OffsiteDrawer (button, desktopNavMinWidth) {

		var self = this;

		this.button = button;

		// get drawer id
		if (this.button.hasAttribute('data-drawer')) {
			this.drawerID = this.button.getAttribute('data-drawer');
		}
		if (typeof(this.drawerID) == 'undefined' || this.drawerID == null) {
			throw new Error('Button has no data-drawer attribute.');
			return;
		}

		// get drawer
		this.drawer = document.getElementById(this.drawerID);
		if (typeof(this.drawer) == 'undefined' || this.drawer == null) {
			throw new Error('Drawer `' + this.drawerID + '` does not exist.');
			return;
		}

		// get direction
		this.direction = 'left';
		if (this.drawer.classList.contains('-drawer-right')) {
			this.direction = 'right';
		}

		// get menu breakpoint width
		if (typeof(desktopNavMinWidth) === 'undefined' || desktopNavMinWidth == null) {
			this.desktopNavMinWidth = 1120;
		} else {
			this.desktopNavMinWidth = desktopNavMinWidth;
		}
		this.isMobileView = true;

		// Add (initial) button semantics
		this.button.setAttribute('aria-haspopup', true);
		this.button.setAttribute('aria-expanded', false);
		this.button.setAttribute('role', 'button');
		this.button.setAttribute('aria-controls', this.drawerID);

		// Handle button click
		this.button.addEventListener('click', function (e) {
			if (this.isMobileView) { // mobile view
				e.preventDefault();
				this.toggle();
			}
		}.bind(this))

		// Also toggle on key interactions
		this.button.addEventListener('keydown', function (e) {
			if (this.isMobileView) { // mobile view
				if (e.keyCode === 13 || e.keyCode === 32) {
					// enter or space
					e.preventDefault();
					this.toggle();

				} else if (e.keyCode === 40) {
					// down arrow
					if (this.button.getAttribute('aria-expanded') !== 'true') {
						e.preventDefault();
						this.open();
						this.button.focus();
					} else {
						e.preventDefault();
						// focus first thing in drawer
						this.drawerItems[0].focus();
					}

				} else if ((e.shiftKey && e.keyCode === 9)) {
					// shift+tab
					this.close(false);

				} else if (e.keyCode === 9) {
					// or tab
					if (this.button.getAttribute('aria-expanded') === 'true') {
						e.preventDefault();
						// focus first thing in drawer
						this.drawerItems[0].focus();
					}

				} else if (e.keyCode === 38) {
					// up arrow
					this.close();

				} else if (e.keyCode === 27) {
					// escape
					this.close();
				}
			}
		}.bind(this))

		window.addEventListener('resize', throttle(function() {
			var width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
			if (width >= self.desktopNavMinWidth) {
				// desktop view
				self.isMobileView = false;
			} else {
				// mobile view
				self.isMobileView = true;
			}
		}, 200));
		// trigger resize event
		if (typeof(Event) === 'function') {
			// modern browsers
			 window.dispatchEvent(new Event('resize'));
		} else {
			// for IE and other old browsers
			// causes deprecation warning on modern browsers
			var evt = window.document.createEvent('UIEvents');
			evt.initUIEvent('resize', true, false, window, 0);
			window.dispatchEvent(evt);
		}

		// Get the all focusable elements within the menu
		this.drawerItems = this.drawer.querySelectorAll(
			'a[href], ' +
			'button:not([disabled]), ' +
			'input:not([disabled]), ' +
			'textarea:not([disabled]), ' +
			'select:not([disabled]), ' +
			'details, ' +
			'[tabindex]:not([tabindex="-1"])'
		);
		// filter drawer items
		this.drawerItems = Array.from(this.drawerItems).filter(function (item) {
			return !item.parentElement.closest('.js-offsitedrawer-skip-children') && !item.classList.contains('js-offsitedrawer-skip');
		});

		// Handle key presses for drawer items
		Array.prototype.forEach.call(this.drawerItems, function (item, index) {
			// add keyboard event
			item.addEventListener('keydown', function (e) {
				if (this.isMobileView) { // mobile view
					if (e.shiftKey && e.keyCode === 9) {
						// shift+tab
						e.preventDefault();
						focusNext(e.target, false);
					} else if (e.keyCode === 38) {
						// up arrow
						e.preventDefault();
						focusNext(e.target, false);
					} else if (e.keyCode === 40 || e.keyCode === 9) {
						// down arrow or tab
						e.preventDefault();
						focusNext(e.target, true);
					} else if (e.keyCode === 27) {
						// escape
						e.preventDefault();
						this.close();
					}
				}
			}.bind(this))
		}.bind(this))

		var focusNext = function (item, down) {

			var nextItem = getNext(item, down);

			// make move
			if (nextItem !== item) {
				nextItem.focus();
			}

		}.bind(this)

		var getNext = function (item, down) {

			var currentItem = item;
			var goingDown = down;
			var currentIndex = 0;
			var nextItem = null;

			console.log('offsite drawer');

			console.log('drawer elements: ' + this.drawerItems.length);

			if (this.drawerItems.length == 0) {
				nextItem = this.button;
			} else {

				// determine current index
				Array.prototype.forEach.call(this.drawerItems, function (item, index) {
					if (item == currentItem) {
						currentIndex = index;
					}
				});

				console.log('current drawer index: ' + currentIndex);

				// determine next item
				if (goingDown) {
					console.log('drawer going down');

					if(typeof this.drawerItems[currentIndex + 1] !== 'undefined') {
						nextItem = this.drawerItems[currentIndex + 1];
					} else {
						nextItem = currentItem;
					}

				} else {
					console.log('drawer going up');

					if (currentIndex == 0) {
						nextItem = this.button;
					} else {
						nextItem = this.drawerItems[currentIndex - 1];
					}
				}

			}

			function shouldElementBeSkipped(element) {
				// check if element is visible
				if (element.offsetParent === null) {
					return true;
				}
				return false;
			}

			// check if element should be skipped
			if (shouldElementBeSkipped(nextItem)) {
				nextItem = getNext(nextItem, down);
			}

			return nextItem;

		}.bind(this)

		// register outside click
		this.outsideClick = function (e) {
			if (this.isMobileView) { // mobile view
				if (this.button.getAttribute('aria-expanded') === 'true' && !this.drawer.contains(e.target) && !this.button.contains(e.target)) {
					this.close(false);
				}
			}
		}
		document.addEventListener('click', this.outsideClick.bind(this));

		// initiate listeners object for public events
		this._listeners = {}
	}

	// Open methods
	OffsiteDrawer.prototype.open = function () {
		// open nav
		this.button.setAttribute('aria-expanded', true);
		// mark body
		document.body.classList.add('js-drawer-open-' + this.direction);
		// fire open event
		this._fire('open');
		return this;
	}

	// Close methods
	OffsiteDrawer.prototype.close = function (setFocus) {
		setFocus = typeof setFocus === "undefined" ? true : setFocus;
		// close nav
		this.button.setAttribute('aria-expanded', false);
		// mark body
		document.body.classList.remove('js-drawer-open-' + this.direction);
		// set focus
		if (setFocus) {
			this.button.focus();
		}
		// fire close event
		this._fire('close');
		return this;
	}

	// Toggle methods
	OffsiteDrawer.prototype.toggle = function () {
		var expanded = this.button.getAttribute('aria-expanded') === 'true';
		return expanded ? this.close() : this.open();
	}

	OffsiteDrawer.prototype._fire = function (type, data) {
		if (typeof this._listeners === 'undefined') {
			this._listeners = [];
		}
		var listeners = this._listeners[type] || [];
		listeners.forEach(function (listener) {
			listener(data);
		})
	}

	OffsiteDrawer.prototype.on = function (type, handler) {
		if (typeof this._listeners[type] === 'undefined') {
			this._listeners[type] = [];
		}
		this._listeners[type].push(handler);
		return this;
	}

	OffsiteDrawer.prototype.off = function (type, handler) {
		var index = this._listeners[type].indexOf(handler);
		if (index > -1) {
			this._listeners[type].splice(index, 1);
		}
		return this;
	}

	// Export OffsiteDrawer
	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		module.exports = OffsiteDrawer;
	} else if (typeof define === 'function' && define.amd) {
		define('OffsiteDrawer', [], function () {
			return OffsiteDrawer;
		})
	} else {
		// attach to window
		window.OffsiteDrawer = OffsiteDrawer;
	}
}());
