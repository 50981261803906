var lazyloadElements = function (elements, callback) {
    if ("IntersectionObserver" in window) {
        var observer = new IntersectionObserver(function(entries, observer) {
            entries.forEach(function(entry) {
                if (entry.isIntersecting) {
                    callback(entry.target);
                    observer.unobserve(entry.target);
                }
            });
        });

        elements.forEach(function(element) {
            observer.observe(element);
        });
    } else {
        var lazyloadThrottleTimeout;

        var isVisible = function (elem) {
            var bounding = elem.getBoundingClientRect();
            var vHeight = (window.innerHeight || document.documentElement.clientHeight);
            return (
                (bounding.top > 0 || bounding.bottom > 0)
                && bounding.top < vHeight
                && elem.offsetParent !== null // hidden by styles
            );
        };

        function lazyload () {
            if(lazyloadThrottleTimeout) {
                clearTimeout(lazyloadThrottleTimeout);
            }
            lazyloadThrottleTimeout = setTimeout(function() {
                elements.forEach(function(element) {
                    if(isVisible(element)) {
                        callback(element);
                    }
                });
                if(elements.length == 0) {
                    document.removeEventListener("scroll", lazyload);
                    window.removeEventListener("resize", lazyload);
                    window.removeEventListener("orientationChange", lazyload);
                }
            }, 200);
        }

        document.addEventListener("scroll", lazyload);
        window.addEventListener("resize", lazyload);
        window.addEventListener("orientationChange", lazyload);
        lazyload();
    }
};
