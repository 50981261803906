;(function () {
    'use strict';

    // init main navigation
    ready(function () {
        var buttons = document.querySelectorAll('.js-submenu-slider-button');
        if (buttons.length > 0) {
            Array.prototype.forEach.call(buttons, function (button) {
                var slider = new SubmenuSlider(button, 1120);
            });
        }
    });

    // Constructor
    function SubmenuSlider(button, desktopNavMinWidth)
    {

        var self = this;

        this.button = button;

        // get drawer id
        if (this.button.hasAttribute('data-slide')) {
            this.slideID = this.button.getAttribute('data-slide');
        }
        if (typeof(this.slideID) == 'undefined' || this.slideID == null) {
            console.error('Button has no data-slide attribute.');
            return;
        }

        // get slide
        this.slide = document.getElementById(this.slideID);
        if (typeof(this.slide) == 'undefined' || this.slide == null) {
            console.error('Slide `' + this.slideID + '` does not exist.');
            return;
        }

        // get back button
        this.backbutton = this.slide.querySelector('.js-submenu-slider-backbutton');
        if (typeof(this.backbutton) == 'undefined' || this.backbutton == null) {
            console.error('Back button in slide `' + this.slideID + '` does not exist.');
            return;
        }

        // get menu breakpoint width
        if (typeof(desktopNavMinWidth) === 'undefined' || desktopNavMinWidth == null) {
            this.desktopNavMinWidth = 1120;
        } else {
            this.desktopNavMinWidth = desktopNavMinWidth;
        }
        this.isMobileView = true;

        // update view type on resize
        window.addEventListener('resize', throttle(function () {
            var width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
            if (width >= self.desktopNavMinWidth) {
                // desktop view
                self.isMobileView = false;
            } else {
                // mobile view
                self.isMobileView = true;
            }
        }, 200));
        // trigger resize event
        if (typeof(Event) === 'function') {
            // modern browsers
             window.dispatchEvent(new Event('resize'));
        } else {
            // for IE and other old browsers
            // causes deprecation warning on modern browsers
            var evt = window.document.createEvent('UIEvents');
            evt.initUIEvent('resize', true, false, window, 0);
            window.dispatchEvent(evt);
        }

        // Add (initial) button semantics
        this.button.setAttribute('aria-haspopup', true);
        this.button.setAttribute('aria-expanded', false);
        this.button.setAttribute('role', 'button');
        this.button.setAttribute('aria-controls', this.slideID);

        // Handle button click
        this.button.addEventListener('click', function (e) {
            if (this.isMobileView) { // mobile view
                e.preventDefault();
                this.open();
            }
        }.bind(this))

        // Also toggle on key interactions
        this.button.addEventListener('keydown', function (e) {
            if (this.isMobileView) { // mobile view
                if (e.keyCode === 13 || e.keyCode === 32) {
                    // enter or space
                    e.preventDefault();
                    this.open();
                    this.backbutton.focus();
                } else if (e.keyCode === 39) {
                    // right arrow
                    if (this.button.getAttribute('aria-expanded') !== 'true') {
                        e.preventDefault();
                        this.open();
                        this.backbutton.focus();
                    } else {
                        e.preventDefault();
                        // focus first thing in drawer
                        this.backbutton.focus();
                    }
                } else if ((e.shiftKey && e.keyCode === 9)) {
                    // shift+tab
                    if (this.button.getAttribute('aria-expanded') === 'true') {
                        this.close(false);
                    }
                } else if (e.keyCode === 9) {
                    // or tab
                    if (this.button.getAttribute('aria-expanded') === 'true') {
                        e.preventDefault();
                        // focus first thing in drawer
                        this.backbutton.focus();
                    }
                } else if (e.keyCode === 38 || e.keyCode === 37) {
                    // up or left arrow
                    if (this.button.getAttribute('aria-expanded') === 'true') {
                        this.close();
                    }
                } else if (e.keyCode === 27) {
                    // escape
                    this.close();
                }
            }
        }.bind(this))

        // Add (initial) back button semantics
        this.backbutton.setAttribute('aria-haspopup', true);
        this.backbutton.setAttribute('aria-expanded', false);
        this.backbutton.setAttribute('role', 'button');
        this.backbutton.setAttribute('aria-controls', this.slideID);

        // Handle button click
        this.backbutton.addEventListener('click', function (e) {
            if (this.isMobileView) { // mobile view
                e.preventDefault();
                this.close(false);
            }
        }.bind(this))

        // Also toggle on key interactions
        this.backbutton.addEventListener('keydown', function (e) {
            if (this.isMobileView) { // mobile view
                if (e.keyCode === 13 || e.keyCode === 32) {
                    // enter or space
                    e.preventDefault();
                    this.close();
                } else if (e.keyCode === 40) {
                    // down arrow
                    e.preventDefault();
                    // focus first thing in drawer
                    this.sliderItems[0].focus();
                } else if ((e.shiftKey && e.keyCode === 9)) {
                    // shift+tab
                    this.close();
                } else if (e.keyCode === 9) {
                    // or tab
                    e.preventDefault();
                    // focus first thing in drawer
                    this.sliderItems[0].focus();
                } else if (e.keyCode === 38 || e.keyCode === 37) {
                    // up or left arrow
                    this.close();
                } else if (e.keyCode === 27) {
                    // escape
                    this.close();
                }
            }
        }.bind(this))

        // Get the all focusable elements within the menu
        this.sliderItems = this.slide.querySelectorAll(
            'a[href], ' +
            'button:not([disabled]):not(.js-submenu-slider-backbutton), ' +
            'input:not([disabled]), ' +
            'textarea:not([disabled]), ' +
            'select:not([disabled]), ' +
            'details, ' +
            '[tabindex]:not([tabindex="-1"])'
        );
        // filter drawer items
        this.sliderItems = Array.from(this.sliderItems).filter(function (item) {
            return !item.parentElement.closest('.js-submenu-slider-skip-children');
        });

        // Handle key presses for drawer items
        Array.prototype.forEach.call(this.sliderItems, function (item) {
            // add keyboard event
            item.addEventListener('keydown', function (e) {
                if (this.isMobileView) { // mobile view
                    if (e.shiftKey && e.keyCode === 9) {
                        // shift+tab
                        e.preventDefault();
                        focusNext(e.target, false);
                    } else if (e.keyCode === 38) {
                        // up arrow
                        e.preventDefault();
                        focusNext(e.target, false);
                    } else if (e.keyCode === 40 || e.keyCode === 9) {
                        // down arrow or tab
                        e.preventDefault();
                        focusNext(e.target, true);
                    } else if (e.keyCode === 27) {
                        // escape
                        e.preventDefault();
                        this.close();
                    }
                }
            }.bind(this))
        }.bind(this))

        var focusNext = function (item, down) {

            var nextItem = getNext(item, down);

            // make move
            if (nextItem !== item) {
                nextItem.focus();
            }

        }.bind(this)

        var getNext = function (item, down) {

            var currentItem = item;
            var goingDown = down;
            var currentIndex = 0;
            var nextItem = null;

            console.log('mobile submenu slider');

            console.log('mobile submenu elements: ' + this.sliderItems.length);

            if (this.sliderItems.length == 0) {
                nextItem = this.backbutton;
            } else {
                // determine current index
                Array.prototype.forEach.call(this.sliderItems, function (tmp, index) {
                    if (tmp == currentItem) {
                        currentIndex = index;
                    }
                });

                console.log('current mobile submenu index: ' + currentIndex);

                // determine next item
                if (goingDown) {
                    console.log('mobile submenu going down');

                    if (typeof this.sliderItems[currentIndex + 1] !== 'undefined') {
                        nextItem = this.sliderItems[currentIndex + 1];
                    } else {
                        nextItem = currentItem;
                    }
                } else {
                    console.log('mobile submenu going up');

                    if (currentIndex == 0) {
                        nextItem = this.backbutton;
                    } else {
                        nextItem = this.sliderItems[currentIndex - 1];
                    }
                }
            }

            function shouldElementBeSkipped(element)
            {
                // check if element is visible
                if (element.offsetParent === null) {
                    return true;
                }
                return false;
            }

            // check if element should be skipped
            if (shouldElementBeSkipped(nextItem)) {
                console.log('skip');
                nextItem = getNext(nextItem, down);
            }

            return nextItem;

        }.bind(this)

        // initiate listeners object for public events
        this._listeners = {}
    }

    // Open methods
    SubmenuSlider.prototype.open = function () {
        // open nav
        this.button.setAttribute('aria-expanded', true);
        this.backbutton.setAttribute('aria-expanded', true);
        // mark slide
        this.slide.classList.add('js-submenu-slider-open');
        // mark parent drawer
        this.slide.closest('.OffCanvasDrawer__Drawer').classList.add('js-fix-scroll');
        // fire open event
        this._fire('open');
        return this;
    }

    // Close methods
    SubmenuSlider.prototype.close = function (setFocus) {
        setFocus = typeof setFocus === "undefined" ? true : setFocus;
        // close nav
        this.button.setAttribute('aria-expanded', false);
        this.backbutton.setAttribute('aria-expanded', false);
        // mark slide
        this.slide.classList.remove('js-submenu-slider-open');
        // mark parent drawer
        this.slide.closest('.OffCanvasDrawer__Drawer').classList.remove('js-fix-scroll');
        // set focus
        if (setFocus) {
            this.button.focus();
        }
        // fire close event
        this._fire('close');
        return this;
    }

    // Toggle methods
    SubmenuSlider.prototype.toggle = function () {
        var expanded = this.button.getAttribute('aria-expanded') === 'true';
        return expanded ? this.close() : this.open();
    }

    SubmenuSlider.prototype._fire = function (type, data) {
        if (typeof this._listeners === 'undefined') {
            this._listeners = [];
        }
        var listeners = this._listeners[type] || [];
        listeners.forEach(function (listener) {
            listener(data);
        })
    }

    SubmenuSlider.prototype.on = function (type, handler) {
        if (typeof this._listeners[type] === 'undefined') {
            this._listeners[type] = [];
        }
        this._listeners[type].push(handler);
        return this;
    }

    SubmenuSlider.prototype.off = function (type, handler) {
        var index = this._listeners[type].indexOf(handler);
        if (index > -1) {
            this._listeners[type].splice(index, 1);
        }
        return this;
    }

    // Export SubmenuSlider
    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = SubmenuSlider;
    } else if (typeof define === 'function' && define.amd) {
        define('SubmenuSlider', [], function () {
            return SubmenuSlider;
        })
    } else {
        // attach to window
        window.SubmenuSlider = SubmenuSlider;
    }
}());
