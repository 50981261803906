;(function () {
	'use strict';
	
	// hide page block if empty to fix spacing issue
	ready(function() {
		var blocks = document.querySelectorAll('.l-Page__block');
		if (blocks.length > 0) {
			Array.prototype.forEach.call(blocks, function (block, index) {
				if(block.getElementsByTagName('*').length == 0){
					block.style.display = 'none';
				}
			});
		}
	});

}());
