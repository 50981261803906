;(function () {
	'use strict';
	
	// init main navigation
	ready(function() {
		var buttons = document.querySelectorAll('.js-search-toggle');
		if (buttons.length > 0) {
			Array.prototype.forEach.call(buttons, function (button, index) {
				var nav = new SearchArea(button, 1120);
			});
		}
	});

	// Constructor
	function SearchArea (button, desktopNavMinWidth) {
		
		var self = this;

		this.button = button;
		
		// get area id
		if (this.button.hasAttribute('data-search')) {
			this.areaID = this.button.getAttribute('data-search');
		}
		if (typeof(this.areaID) == 'undefined' || this.areaID == null) {
			throw new Error('Button has no data-search attribute.');
			return;
		}
		
		// get drawer
		this.area = document.getElementById(this.areaID);
		if (typeof(this.area) == 'undefined' || this.area == null) {
			throw new Error('Area `' + this.areaID + '` does not exist.');
			return;
		}
		
		// get menu breakpoint width
		if (typeof(desktopNavMinWidth) === 'undefined' || desktopNavMinWidth == null) {
			this.desktopNavMinWidth = 1024;
		} else {
			this.desktopNavMinWidth = desktopNavMinWidth;
		}
		this.isMobileView = true;
		
		// Add (initial) button semantics
		this.button.setAttribute('aria-haspopup', true);
		this.button.setAttribute('aria-expanded', false);
		this.button.setAttribute('role', 'button');
		this.button.setAttribute('aria-controls', this.areaID);

		// Handle button click
		this.button.addEventListener('click', function (e) {
			if (!this.isMobileView) { // not mobile view
				console.log('click');
				e.preventDefault();
				this.toggle();
			}
		}.bind(this))

		// Also toggle on key interactions
		this.button.addEventListener('keydown', function (e) {
			if (!this.isMobileView) { // not mobile view
				if (e.keyCode === 13 || e.keyCode === 32) { 
					// enter or space
					console.log('enter/space');
					e.preventDefault();
					this.toggle();
					
				} else if (e.keyCode === 40) { 
					// down arrow
					console.log('arrow down');
					if (this.button.getAttribute('aria-expanded') !== 'true') {
						e.preventDefault();
						this.open();
						this.button.focus();
					} else {
						e.preventDefault();
						// focus first thing in drawer
						this.areaItems[0].focus();
					}
					
				} else if ((e.shiftKey && e.keyCode === 9)) { 
					// shift+tab
					console.log('shift+tab');
					this.close(false);
					
				} else if (e.keyCode === 9) { 
					// or tab
					console.log('tab');
					if (this.button.getAttribute('aria-expanded') === 'true') {
						e.preventDefault();
						// focus first thing in drawer
						this.areaItems[0].focus();
					}
					
				} else if (e.keyCode === 38) { 
					// up arrow
					console.log('arrow up');
					this.close();
					
				} else if (e.keyCode === 27) { 
					// escape
					console.log('escape');
					this.close();
				}
			}
		}.bind(this))
		
		// get additional close button
		if (this.button.hasAttribute('data-search-close')) {
			this.closeButtonId = this.button.getAttribute('data-search-close');
		}
		if (typeof(this.closeButtonId) != 'undefined' && this.closeButtonId != null) {
			this.closeButton = document.getElementById(this.closeButtonId);
			if (typeof(this.closeButton) != 'undefined' && this.closeButton != null) {
				// setup close button
				this.closeButton.setAttribute('aria-haspopup', true);
				this.closeButton.setAttribute('aria-expanded', this.button.getAttribute('aria-expanded'));
				this.closeButton.setAttribute('role', 'button');
				this.closeButton.setAttribute('aria-controls', this.areaID);
				// Handle button click
				this.closeButton.addEventListener('click', function (e) {
					if (!this.isMobileView) { // not mobile view
						e.preventDefault();
						this.close();
					}
				}.bind(this))
				// handle key interactions
				this.closeButton.addEventListener('keydown', function (e) {
					if (!this.isMobileView) { // not mobile view
						if (e.keyCode === 13 || e.keyCode === 32) { 
							// enter or space
							e.preventDefault();
							this.close();
						}
					}
				}.bind(this))
			}
		}
		
		window.addEventListener('resize', throttle(function() {
			var width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
			if (width >= self.desktopNavMinWidth) {
				// desktop view
				self.isMobileView = false;
			} else {
				// mobile view
				self.isMobileView = true;
			}
		}, 200));
		// trigger resize event
		if (typeof(Event) === 'function') {
			// modern browsers
			 window.dispatchEvent(new Event('resize'));
		} else {
			// for IE and other old browsers
			// causes deprecation warning on modern browsers
			var evt = window.document.createEvent('UIEvents'); 
			evt.initUIEvent('resize', true, false, window, 0); 
			window.dispatchEvent(evt);
		}
		
		// Get the all focusable elements within the menu
		this.areaItems = this.area.querySelectorAll(
			'a[href], ' + 
			'button:not([disabled]), ' + 
			'input:not([disabled]), ' + 
			'textarea:not([disabled]), ' + 
			'select:not([disabled]), ' + 
			'details, ' + 
			'[tabindex]:not([tabindex="-1"])'
		);
		
		// Handle key presses for drawer items
		Array.prototype.forEach.call(this.areaItems, function (item, index) {
			// add keyboard event
			item.addEventListener('keydown', function (e) {
				if (!this.isMobileView) { // not mobile view
					if (e.shiftKey && e.keyCode === 9) { 
						// shift+tab
						e.preventDefault();
						focusNext(e.target, false);
					} else if (e.keyCode === 9) { 
						// tab
						e.preventDefault();
						focusNext(e.target, true);
					} else if (e.keyCode === 27) { 
						// escape
						e.preventDefault();
						this.close();
					}
				}
			}.bind(this))
		}.bind(this))

		var focusNext = function (item, down) {

			var nextItem = getNext(item, down);
			
			// make move
			if (nextItem !== item) {
				nextItem.focus();
			}

		}.bind(this)
		
		var getNext = function (item, down) {

			var currentItem = item;
			var goingDown = down;
			var currentIndex = 0;
			var nextItem = null;
			
			console.log('search area');
			
			console.log('area elements: ' + this.areaItems.length);
			
			if (this.areaItems.length == 0) {
				nextItem = this.button;
			} else {
			
				// determine current index
				Array.prototype.forEach.call(this.areaItems, function (item, index) {
					if (item == currentItem) {
						currentIndex = index;
					}
				});
				
				console.log('current area index: ' + currentIndex);
				
				// determine next item
				if (goingDown) {
					console.log('area going down');
					
					if(typeof this.areaItems[currentIndex + 1] !== 'undefined') {
						nextItem = this.areaItems[currentIndex + 1];
					} else {
						nextItem = currentItem;
					}
					
				} else {
					console.log('area going up');
					
					if (currentIndex == 0) {
						nextItem = this.button;
					} else {
						nextItem = this.areaItems[currentIndex - 1];
					}
				}
				
			}
				
			function shouldElementBeSkipped(element) {
				// check if element is visible
				if (element.offsetParent === null) {
					return true;
				}
				return false;
			}
			
			// check if element should be skipped
			if (shouldElementBeSkipped(nextItem)) {
				nextItem = getNext(nextItem, down);
			}
			
			return nextItem;

		}.bind(this)
		
		// register outside click
		this.outsideClick = function (e) {
			if (!this.isMobileView) { // not mobile view
				if (this.button.getAttribute('aria-expanded') === 'true' && !this.area.contains(e.target) && !this.button.contains(e.target)) {
					console.log('outside click');
					this.close(false);
				}
			}
		}
		document.addEventListener('click', this.outsideClick.bind(this));
		
		// initiate listeners object for public events
		this._listeners = {}
	}
	
	// Open methods
	SearchArea.prototype.open = function () {
		console.log('open');
		// mark button
		this.button.setAttribute('aria-expanded', true);
		// mark close button
		if (typeof(this.closeButton) != 'undefined' && this.closeButton != null) {
			this.closeButton.setAttribute('aria-expanded', true);
		}
		// mark body
		this.area.classList.add('js-search-open');
		// set focus
		var searchField = this.area.querySelector('input[type="search"]:not([disabled])');
		if (typeof(searchField) != 'undefined' && searchField != null) {
			searchField.focus();
		}
		// fire open event
		this._fire('open');
		return this;
	}

	// Close methods
	SearchArea.prototype.close = function (setFocus) {
		console.log('close');
		setFocus = typeof setFocus === "undefined" ? true : setFocus;
		// mark button
		this.button.setAttribute('aria-expanded', false);
		// mark close button
		if (typeof(this.closeButton) != 'undefined' && this.closeButton != null) {
			this.closeButton.setAttribute('aria-expanded', false);
		}
		// mark body
		this.area.classList.remove('js-search-open');
		// set focus
		if (setFocus) {
			this.button.focus();
		}
		// fire close event
		this._fire('close');
		return this;
	}

	// Toggle methods
	SearchArea.prototype.toggle = function () {
		console.log('toggle');
		var expanded = this.button.getAttribute('aria-expanded') === 'true';
		return expanded ? this.close() : this.open();
	}
	
	SearchArea.prototype._fire = function (type, data) {
		if (typeof this._listeners === 'undefined') {
			this._listeners = [];
		}
		var listeners = this._listeners[type] || [];
		listeners.forEach(function (listener) {
			listener(data);
		})
	}

	SearchArea.prototype.on = function (type, handler) {
		if (typeof this._listeners[type] === 'undefined') {
			this._listeners[type] = [];
		}
		this._listeners[type].push(handler);
		return this;
	}

	SearchArea.prototype.off = function (type, handler) {
		var index = this._listeners[type].indexOf(handler);
		if (index > -1) {
			this._listeners[type].splice(index, 1);
		}
		return this;
	}

	// Export SearchArea
	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		module.exports = SearchArea;
	} else if (typeof define === 'function' && define.amd) {
		define('SearchArea', [], function () {
			return SearchArea;
		})
	} else {
		// attach to window
		window.SearchArea = SearchArea;
	}
}());
