;(function () {
	'use strict';
	
	// init main navigation
	ready(function() {
		var form = document.querySelector('.js-filter-form');
		if (typeof(form) != 'undefined' && form != null) {
			var dropdowns = form.querySelectorAll('select');
			if (dropdowns.length > 0) {
				Array.prototype.forEach.call(dropdowns, function (dropdown, index) {
					dropdown.addEventListener('change', function (e) {
						e.preventDefault();
						if (dropdown.classList.contains('js-sumbit-form')) {
							dropdown.form.submit();
						} else {
							window.location.href = dropdown.value;
						}
					});
				});
			}
		}
	});

}());
