;(function () {
    'use strict';

    ready(function () {
        setTimeout(function () {
            var hash = location.hash;
            if (hash[0] === '#') {
                hash = hash.slice(1);
                var hashElement = document.getElementById(hash);
                if (typeof hashElement !== 'undefined' && hashElement !== null) {
                    if (!hashElement.classList.contains('js-accordion')) {
                        hash = false;
                    }
                } else {
                    hash = false;
                }
            } else {
                hash = false;
            }
            initAccordions(hash);
        }, 20);
    });

    var initAccordions = function (hash) {

        var accordions = document.querySelectorAll('.js-accordion');

        Array.prototype.forEach.call(accordions, function (accordion, i) {

            var accordionID = accordion.getAttribute('ID');
            var buttonClass = accordion.getAttribute('data-accordion-button-class');
            var openPanelButton = false;

            var header = accordion.querySelector('.js-accordion-header');
            var headerContent = header.innerHTML;
            var button = document.createElement('button');
            header.classList.remove('js-accordion-header');
            button.classList.add('js-accordion-header');
            button.classList.add(buttonClass);
            button.innerHTML = headerContent;
            header.innerHTML = "";
            header.appendChild(button);

            if (hash && hash === accordionID) {
                openPanelButton = true;
            }

            var baOptions = {
                headerClass: '.js-accordion-header',
                panelClass: '.js-accordion-panel',
                panelInnerClass: '.js-accordion-panel-inner',
                hiddenClass: 'is-hidden',
                initializedClass: 'is-initialized',
                openMultiplePanels: true
            };

            var ba = new BadgerAccordion(accordion, baOptions);

            if (openPanelButton) {
                ba.open(0);
                console.log(accordion.getAttribute('ID'));
                button = accordion.querySelector('.js-accordion-header');
                button.focus();
                button.scrollIntoView();
            }
        });
    };

}());
