;(function () {
    'use strict';

    // lazy load videos
    ready(function() {

        var loadForm = function(form) {
            // load dependencies
            loadJS(form.getAttribute('data-jquery'), true);
            loadJS(form.getAttribute('data-hubspot'), function() {

                // initialise form
                hbspt.forms.create({
                    region: "na1",
                    portalId: form.getAttribute('data-account'),
                    formId: form.getAttribute('data-form'),
                    target: "#" + form.getAttribute('data-target'),
                    cssClass: 'js-no-validation',
                    errorMessageClass: 'message error',
                    submitButtonClass: 'Button action',
                    onFormReady: function($form) {
                        // hide loading message
                        if (form.hasAttribute('data-message') && form.getAttribute('data-message')) {
                            var message = document.getElementById(form.getAttribute('data-message'));
                            if (typeof(message) !== 'undefined' && message !== null) {
                                message.style.display = 'none';
                            }
                        }
                    }
                });
            }, true);

            // set loaded flag
            form.setAttribute('data-hubspot-form-loaded', 'true');
            form.classList.remove('js-load-hubspot-form');
        };

        lazyloadElements(
            document.querySelectorAll('.js-load-hubspot-form'),
            function(form) {
                loadForm(form);
            }
        );

        window.addEventListener('triggerHubSpotForms', function (e) {
            var forms = document.querySelectorAll('.js-load-hubspot-form');
            if (forms.length > 0) {
                Array.prototype.forEach.call(forms, function (form, index) {
                    loadForm(form);
                });
            }
        }, false);

    });

}());

